import { useState } from "react";
import Dashboard from "./Screens/Dashboard";
import Login from "./Screens/Login";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  function authenticate() {
    setAuthenticated(true);
  }
  return authenticated ? <Dashboard /> : <Login auth={() => authenticate()} />;
}

export default App;
