import { Info, Error, ReportProblem } from "@material-ui/icons";
import React from "react";
import "../styles/alert.css";

export default function Alert({ variant, message }) {
  switch (variant) {
    case "info":
      return (
        <div className="info alert">
          <Info /> {message}
        </div>
      );

    case "warn":
      return (
        <div className="warn alert">
          <ReportProblem /> {message}
        </div>
      );
    case "error":
      return (
        <div className="error alert">
          <Error />
          {message}
        </div>
      );
    default:
      return (
        <div className="info alert">
          <Info /> {message}
        </div>
      );
  }
}
