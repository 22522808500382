import { Add, Refresh, Search } from "@material-ui/icons";
import React, { useEffect, useRef } from "react";
import {
  Container,
  Form,
  Stack,
  Button,
  Table,
  Row,
  Col,
  Spinner,
  Badge,
  InputGroup,
  Navbar,
} from "react-bootstrap";
import Alert from "../Components/Alert";
import CenteredContainer from "../Components/CenteredContainer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

export default function Dashboard() {
  const searchRef = useRef();
  const typeRef = useRef();
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");
  const [submitError, setSubmitError] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(true);
  const passIdRef = useRef();
  const holderRef = useRef();
  const typeNewRef = useRef();
  const validRef = useRef();
  const prodNameRef = useRef();

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    fetch(
      "https://greecemybuscorsbypass.herokuapp.com/http://ptx.remote-servers.com:90/quotes/addToPass.php",
      {
        method: "POST",
        headers: {
          authorization: "Basic adcd86ea-a7b7-4837-a5f5-6ad8249f2e58",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `passId=${passIdRef.current.value}&holderName=${holderRef.current.value}&passType=${typeNewRef.current.value}&expDateTime=${validRef.current.value}&prodName=${prodNameRef.current.value}`,
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("Responce");
        console.log(res);
        if (res.code === "201") {
          setSubmitError("");
          setModalShow(false);
          setSubmitting(false);
          fetchPasses();
        } else {
          setSubmitError(res.description);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        setSubmitError(err.statusText);
        setSubmitting(false);
      });
  }

  var dateInPast = function (firstDate, secondDate) {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }

    return false;
  };

  function search(mode) {
    if (mode === 0) {
      if (searchRef.current.value === "") {
        setError("");
        setFilteredData(data);
      } else {
        const filtered = data.filter((item) => {
          return (
            item.holderName
              ?.toLowerCase()
              .includes(searchRef.current.value?.toLowerCase()) ||
            item.pass_id
              ?.toLowerCase()
              .includes(searchRef.current.value?.toLowerCase()) ||
            item.prodName
              ?.toLowerCase()
              .includes(searchRef.current.value?.toLowerCase()) ||
            item.passType
              ?.toLowerCase()
              .includes(searchRef.current.value?.toLowerCase())
          );
        });
        if (filtered.length === 0) {
          setError("No passes found");
        } else {
          setError("");
          setFilteredData(filtered);
        }
      }
    } else if (mode === 1) {
      let filteredFromSearch = data.filter((item) => {
        return (
          item.holderName
            ?.toLowerCase()
            .includes(searchRef.current.value?.toLowerCase()) ||
          item.pass_id
            ?.toLowerCase()
            .includes(searchRef.current.value?.toLowerCase()) ||
          item.prodName
            ?.toLowerCase()
            .includes(searchRef.current.value?.toLowerCase())
        );
      });
      if (filteredFromSearch.length === 0) {
        setError("No passes found");
      } else {
        if (typeRef.current.value === "") {
          filteredFromSearch = data;
        }
        if (typeRef.current.value === "All") {
          setError("");
          setFilteredData(filteredFromSearch);
        } else {
          const finalFilter = filteredFromSearch.filter((item) => {
            return item.passType
              ?.toLowerCase()
              .includes(typeRef.current.value?.toLowerCase());
          });
          setError("");
          setFilteredData(finalFilter);
        }
      }
    }
  }

  function fetchPasses() {
    fetch(
      "https://greecemybuscorsbypass.herokuapp.com/http://ptx.remote-servers.com:90/quotes/getCityFilmPasses.php",
      {
        method: "GET",
        headers: {
          authorization: "Basic adcd86ea-a7b7-4837-a5f5-6ad8249f2e58",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          console.log(result);
          setData(result);
          setFilteredData(result);
          setLoading(false);
          setRefreshing(false);
        },
        (error) => {
          setError(error.message);
        }
      );
  }

  useEffect(() => {
    fetchPasses();
  }, []);

  if (loading) {
    return (
      <CenteredContainer>
        {error && <Alert variant="error" message={error} />}
        <Container className="d-flex flex-column align-items-center justify-content-center">
          <Spinner animation="border" role="status" className="mb-2" />
          Loading...
        </Container>
      </CenteredContainer>
    );
  } else {
    return (
      <>
        <Dialog
          onClose={() => setModalShow(false)}
          aria-labelledby="customized-dialog-title"
          open={modalShow}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setModalShow(false)}
          >
            Add a new pass
          </DialogTitle>
          <DialogContent dividers>
            {submitError && <Alert variant="error" message={submitError} />}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Pass ID</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Pass ID"
                  ref={passIdRef}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter holder name"
                  ref={holderRef}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label="type"
                  ref={typeNewRef}
                  onChange={() => search(1)}
                  required
                >
                  <option disabled>Select pass type</option>
                  <option value="crew">Crew</option>
                  <option value="star">Star</option>
                  <option value="guest">Guest</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Valid until</Form.Label>
                <input
                  type="datetime-local"
                  className="form-control"
                  ref={validRef}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Production name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter production name"
                  ref={prodNameRef}
                  required
                />
              </Form.Group>
              <Button
                type="submit"
                disabled={submitting}
                // onClick={() => setModalShow(false)}
              >
                {submitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Add pass"
                )}
              </Button>
            </Form>
          </DialogContent>
        </Dialog>
        <Navbar>
          <Container>
            <Navbar.Brand>CityFilm Pass Admin</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Signed in as: cityfilm
                <Button
                  className="m-2"
                  variant="outline-dark"
                  onClick={() => window.location.reload()}
                >
                  Signout
                </Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh", maxWidth: "90vw" }}
        >
          <Row>
            <Col>
              {error && <Alert variant="error" message={error} />}
              <Row>
                <Stack direction="horizontal" gap={3} className="mb-3">
                  <Form.Control
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        search(0);
                      }
                    }}
                    ref={searchRef}
                    className="me-auto"
                    placeholder="Search for a pass..."
                  />
                  <Button variant="secondary" onClick={() => search(0)}>
                    <Search /> Search
                  </Button>
                  <div className="vr" />
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    <Add />
                    Add
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={() => {
                      setRefreshing(true);
                      fetchPasses();
                    }}
                    disabled={refreshing}
                  >
                    <Refresh />
                    {refreshing ? "Reloading..." : "Reload"}
                  </Button>
                </Stack>
              </Row>
              <Row>
                <Stack direction="horizontal" gap={3} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>Filter by type</InputGroup.Text>
                    <Form.Select
                      aria-label="type"
                      ref={typeRef}
                      onChange={() => search(1)}
                    >
                      <option>All</option>
                      <option value="crew">Crew</option>
                      <option value="star">Star</option>
                      <option value="guest">Guest</option>
                    </Form.Select>
                  </InputGroup>
                </Stack>
              </Row>
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>PassID</th>
                      <th>Holder Name</th>
                      <th>Type</th>
                      <th>Valid Until</th>
                      <th>Production</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0
                      ? filteredData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id ?? "Not found"}</td>
                            <td>{item.pass_id ?? "Not found"}</td>
                            <td>{item.holderName ?? "Not found"}</td>
                            <td>{item.passType ?? "Not found"}</td>
                            <td>
                              {Intl.DateTimeFormat("el-GR", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(new Date(item.expDateTime))}
                              {dateInPast(
                                new Date(item.expDateTime),
                                new Date()
                              ) ? (
                                <>
                                  &nbsp;
                                  <Badge pill bg="danger">
                                    EXPIRED
                                  </Badge>
                                </>
                              ) : (
                                "s"
                              )}
                            </td>

                            <td>{item.prodName}</td>
                          </tr>
                        ))
                      : "No results"}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
