import React, { useRef, useState } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import Alert from "../Components/Alert";
import CenteredContainer from "../Components/CenteredContainer";

export default function Login({ auth }) {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError(
      "Username: " +
        usernameRef.current.value +
        " Password: " +
        passwordRef.current.value
    );

    if (
      usernameRef.current.value === "cityfilm" &&
      passwordRef.current.value === "EOu#Fxx#lI&u"
    ) {
      setLoading(false);
      auth();
    } else {
      setLoading(false);
      setError("Invalid username or password");
    }
    // try {
    //   setError("");
    //   setLoading(true);
    //   await login(emailRef.current.value, passwordRef.current.value);
    //   history.push("/");
    // } catch {
    //   setError("Failed to log in");
    // }

    // setLoading(false);
  }

  return (
    <CenteredContainer>
      {error && <Alert variant="error" message={error} />}
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder="Enter username"
                ref={usernameRef}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                ref={passwordRef}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </CenteredContainer>
  );
}
